@import "../abstracts/vars";

body {
    //Bannière de haut de page de l'article
     .bg-article {
         background-image: url("../../img/covid.png");
         background-position: bottom;
         background-repeat: no-repeat;
         background-size: cover;

         h2 {
             font-size: 0.95em;
         }
     }
    .jumbotron {
        border-radius: 0!important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
    }

    .article-header {
        padding: 20vh 0 0 0;
        color: white;
        background-position: center;
        background-size: cover;
        @media (max-width: 768px) {
            padding: 10vh 2.5% 1vh;
        }
    }
    ul {
        list-style: none;
        li {
          position: relative;
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 45%;
                background-color: $main-blue;
                position: absolute;
                top: 10px;
                left: -25px;
            }
        }
    }
    .exergue {
        background-color: lighten(lightgrey, 13%);
        border-radius: 3px;

        .exergue-text {
            font-size: 1.3rem;
            position: relative;

            &::before, &::after {
                position: absolute;
                display: inline-block;
                font-size: 70px;
                opacity: .2;
            }

            &::before {
                left: 0;
                top: 0;
                content: '\201C';
                margin-right: 4px;
                margin-left: -8px;
                line-height: 65px;
            }

            &::after {
                content: '\201D';
                margin-top: 10px;
                margin-left: 4px;
                margin-right: -8px;
                position: absolute;
                right: 0;
                bottom: 0;
                line-height: 0;
            }
        }
    }

    //CSS pour le composant de partage social
    .share-container {
        border: 1px solid #dedede;
        height: 180px;

        &.sticky {
            position: sticky;
            top: 200px;
        }

        .icon-box {
            border: 1px solid #dedede;
            width: 50px;
            height: 50px;
            display: block;
            padding-top: 12px;
            cursor: pointer;

            &:hover svg {
                color: white;
            }

            &.fb {
                &:hover {
                    background-color: #3b5998;
                }
            }

            &.lk {
                &:hover {
                    background-color: #0e76a8;
                }
            }
        }
    }
}
