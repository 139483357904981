.sticky-top {
    z-index: 10 !important;
}

.nav-item  {
    i {
        color: white;
    }

    .nav-link {
        line-height: 40px;
        max-height: 45px;

        padding-bottom: 0;
        border-bottom: 1px solid transparent;

        transition: border-bottom-color .5s;

        &:hover {
            border-bottom: 1px solid white;
        }
    }
}

.pointer {
    cursor: pointer;
}
.logoheader {
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}