body {
  //Bannière de haut de page de la page Apropos
  .bg-basique {
    background-image: url("../../img/plage.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
  }
}
