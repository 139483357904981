//Main Color
$main-text: #1d1e1c;
$main-blue: #435580;
$hovered-main-blue: darken($main-blue, 10%);

$link-color: white;

//Newsletter
$newsletter-inner-color: white;
$newsletter-background-color: whitesmoke;

//Font-size
$main-font-size: 1em;