@import "../abstracts/vars";

//.modal{
//    left: 0% !important;
//    padding: 0px !important;
//    top: 0!important;
//}

.searchModal {
    .modal-content{
        background-color: transparent;
    }

    .form-control{
        border-radius: 0;
        height: 80px;
        font-size: 1.3rem;
        color: black;
    }

    .form-control:focus{
        border : 0px !important;
        box-shadow: none !important;
    }

    .survol{
        height: 80px !important;
    }

    .survol:hover{
        background-color: $main-blue !important;
        color: white !important;
    }

}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 58%;
    }
}
.newsletterModal {
    & .modal-body {
        padding: 0;
    }
    & h2 {
        font-size: 1.1rem;
    }
    & .modal-content {
        padding: 2rem;
        background-color: #fff;
    }
}