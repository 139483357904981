@import "../abstracts/vars";

body {
    #newsletter-section {
        background-color: $newsletter-background-color;
        position: sticky;
        top: 20%;
        .letter-open-icon {
            color: $main-blue;
        }

        .btn-custom-arrow-button {
            background-color: $main-blue;
            color: $newsletter-inner-color;
            transition: .5s;

            &:hover {
                background-color: $hovered-main-blue;
                color: $newsletter-inner-color;
            }
        }
        strong {
            cursor: pointer;
        }
    }
    .required {
        color: red;
        position: absolute;
        right: -15px;
    }
    .dropdown-toggle {
        background-color: #fff;
        color: #495057;
        border: 1px solid #ced4da;
        &:hover {
            background-color: #fff;
        }
        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }
}