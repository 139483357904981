@import "../abstracts/vars";
@import "../pages/article";
/***** GLOBAL *****/

body, html{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: Poppins, serif;
}

.accueil {
    color: $main-text;
    font-size: $main-font-size;
}

a {
    color: $main-text;

    &:hover, &:visited, &:focus {
        text-decoration: none !important;
        color: $main-text;
    }
}

header a, footer a {
    color: $link-color;

    &:hover, &:visited, &:focus {
        text-decoration: none !important;
        color: $link-color;
    }
}

#logo {
    min-height: 30px;
    min-width: 30px;
}

/********* Bootstrap custom **********/

.bg-blue {
    background-color: $main-blue;
}

.t-blue {
    color: $main-blue;
}

.sujet {
    font-size: 0.8em;
}

.divsujet {
    position: relative;
}

.news-card {
    position: relative;
    transition: all .5s ease;

    &:hover {
        transform: translateY(-5px);
        -webkit-box-shadow: 0 10px 18px -5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0 10px 18px -5px rgba(0,0,0,0.75);
        box-shadow: 0 10px 18px -5px rgba(0,0,0,0.75) !important;
    }
    .title-card {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 15px 15px 10px 15px;
        text-align: justify;
        p {
            margin: 0;
        }
    }
}

/********* modal *********/

.modal.fade {
    top: 0;
}

.modal-content {
    border: 0;
}

//.modal {
//    position: fixed;
//    z-index: 1050;
//    outline: 0;
//    top: 20% !important;
//    left: 50%;
//    bottom: 40%;
//    border-radius: 0;
//    border: 0;
//}

//@media screen and (max-width: 992px) {
//    .modal {
//        left: 10%;
//        right: 10%;
//    }
//}

/****** carrousel *******/

/*.carousel{*/
/*    height: 40vh;*/
/*    width: 50vw;*/
/*}*/

/*.carousel-inner{*/
/*    height: 40vh;*/
/*}*/